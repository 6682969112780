import React from "react"
import { naviLinks } from "./constants/NaviLinks"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

import {
  Typography,
  Toolbar,
  AppBar,
  makeStyles,
  Grid,
  Icon,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import {
  PhoneAndroid,
  CallEnd,
  Copyright,
  Launch,
  LinkedIn,
  LocationOn,
  MailOutline,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: customTheme.palette.primary.main,
    padding: "8rem 0 1rem",
  },
  footerNav: {
    ...theme.typography.tab,
    color: "#FFF",
    textDecoration: "none",
    "&:hover": {
      color: customTheme.palette.primary.light,
    },
  },
  linkedin: {
    width: "100%",
    height: "4rem",
    margin: " 0 auto",
    "& > svg": {
      width: "4rem",
      height: "4rem",
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <footer>
      <AppBar position="static" component="div">
        <Toolbar className={classes.footer} disableGutters>
          <Grid
            container
            className="contentContainer"
            alignItems="flex-start"
            justify="center"
            style={{ margin: "0 auto" }}
          >
            <Grid
              container
              className="contentContainer"
              alignItems="center"
              justify="center"
              direction={matchesSM ? "column" : "row"}
              style={{ margin: "0 auto" }}
            >
              {/* Right column */}
              <Grid
                item
                container
                justify={matchesSM ? "center" : "flex-start"}
                style={{
                  marginLeft: matchesSM ? 0 : "auto",
                  maxWidth: "40rem",
                  flex: "1",
                }}
              >
                <StaticImage
                  src="../images/arLogoTransparent.png"
                  alt="logo"
                  style={{ height: "100%" }}
                />
              </Grid>

              {/* Center column */}
              <Grid
                item
                style={{
                  margin: matchesSM ? "9rem 0" : "0 4rem",
                  zIndex: "1",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  spacing={matchesXS ? 3 : matchesMD ? 4 : 2}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      spacing={1}
                      alignItems={matchesXS ? "center" : "flex-start"}
                    >
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[0].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[0].name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[1].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[1].name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[2].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[2].name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[3].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[3].name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      spacing={1}
                      alignItems={matchesXS ? "center" : "flex-start"}
                    >
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[4].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[4].name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component={Link}
                          to={naviLinks[5].link}
                          className={classes.footerNav}
                        >
                          {naviLinks[5].name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="a"
                          href="https://en.wikipedia.org/wiki/ISO/IEC_27001"
                          className={classes.footerNav}
                        >
                          <Icon>
                            <Launch fontSize="small" />
                          </Icon>
                          ISO
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="a"
                          href="https://en.wikipedia.org/wiki/Payment_Card_Industry_Data_Security_Standard"
                          className={classes.footerNav}
                        >
                          <Icon>
                            <Launch fontSize="small" />
                          </Icon>
                          PCIDSS
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          component="a"
                          href="/document"
                          target="_blank"
                          className={classes.footerNav}
                        >
                          <Icon>
                            <Launch fontSize="small" />
                          </Icon>
                          ISMS Policy
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Left column */}
              <Grid item style={{ marginTop: "-5rem" }}>
                <Grid
                  container
                  direction="column"
                  alignItems={matchesXS ? "center" : "flex-start"}
                  spacing={1}
                >
                  <Grid item container justify="center">
                    <Grid item>
                      <Icon
                        component="a"
                        href="https://www.linkedin.com/company/altamiuz/"
                        className={`${classes.footerNav} ${classes.linkedin}`}
                      >
                        <LinkedIn />
                      </Icon>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row-reverse"
                      spacing={1}
                      justify="center"
                    >
                      <Grid item>
                        <Typography
                          component="a"
                          href="MAILTO:ceo@altamiuz.ly"
                          className={classes.footerNav}
                        >
                          ceo@altamiuz.ly
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Icon>
                          <MailOutline />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row-reverse"
                      spacing={1}
                      justify="center"
                    >
                      <Grid item style={{ direction: "ltr" }}>
                        <Typography
                          component="a"
                          href="TEL:+218917373202"
                          className={classes.footerNav}
                        >
                          +218 91-737-3202
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Icon>
                          <PhoneAndroid />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row-reverse"
                      spacing={1}
                      justify="center"
                    >
                      <Grid item style={{ direction: "ltr" }}>
                        <Typography
                          component="a"
                          href="TEL:+218927373201"
                          className={classes.footerNav}
                        >
                          +218 92-737-3201
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Icon>
                          <PhoneAndroid />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row-reverse"
                      spacing={1}
                      justify="center"
                    >
                      <Grid item style={{ direction: "ltr" }}>
                        <Typography
                          component="a"
                          href="TEL:+218214629298"
                          className={classes.footerNav}
                        >
                          +218 21-462-9298
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Icon>
                          <CallEnd />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row-reverse"
                      spacing={1}
                      justify="center"
                    >
                      <Grid item style={{ direction: "ltr" }}>
                        <Typography
                          component="a"
                          href="https://goo.gl/maps/oup2cQqhg8bqHmDq9"
                          target="_blank"
                          className={classes.footerNav}
                        >
                          ليبيا - طرابلس / السبعة
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Icon>
                          <LocationOn />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Bottom row */}
            <Grid item style={{ marginTop: matchesSM ? "4rem" : 0 }}>
              <Grid container alignItems="center" spacing={1} justify="center">
                <Grid item style={{ justifySelf: "center" }}>
                  <Typography style={{ fontSize: "2rem" }}>
                    شـركـة الـتـمـيـز &nbsp;{new Date().getFullYear()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon>
                    <Copyright />
                  </Icon>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </footer>
  )
}

export default Footer
