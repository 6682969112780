import { courses } from "./Courses"
export const SLIDER_DATA = [
  {
    title: courses[0].title,
    info: courses[0].summary,
    link: courses[0].link,
  },
  {
    title: courses[1].title,
    info: courses[1].summary,
    link: courses[1].link,
  },
  {
    title: courses[2].title,
    info: courses[2].summary,
    link: courses[2].link,
  },
]
