const courses = [
  {
    image: {},
    imageRelativePath: "course-1.jpg",
    alt: "man writing",
    title:
      "دورة كبير مسؤولي تنفيذ نظام إدارة أمن المعلومات ISO27001 LI معتمدة من شركة PECB",
    contains: [
      "مدة الدورة خمسة أيام",
      "بواقع 6 ساعات يوميا",
      "الحقيبة التدريبية تشمل المنهج معتمد + شهادة حضور + شهادة اعتماد بعد اجتياز الامتحان",
      "الدخول للامتحان وإعادة الامتحان مجانا",
      "يمكن التنسيق لتقديم الدورة بدولة تركيا مع تضمين أسعار القاعات وتكاليف السفر والإقامة بعرض منفصل",
    ],
    summary:
      "توجه هذه الدورة التدربية بشكل أساسي لدعم المؤسسات الراغبة في تطبيق وإدارة وصيانة وتحسين نظام إدارة أمن المعلومات على أساس المواصفة الدولية ISO 27001 من خلال نقل المعرفة للأفراد الذين سيتولون هذه الوظيفة بحيث سيكتسبون المهارات لفهم أفضل الممارسات من أجل تحسين أداء وفاعلية نظام إدارة أمن المعلومات بعد اتقان المفاهيم الضرورية لذلك. كما أنه يمكن للأشخاص الراغبين في تنمية مهاراتهم الإدراية لنظم إدارة أمن المعلومات والعمل كمستشارين مستقلين أو تحسين فرصهم الوظيفية التقدم للدورة.",
    goals: [
      "الإلمام بالمعرفة لفهم العلاقة بين جميع مكونات عائلة المواصفة الدولية ISO 27k",
      "امتلاك القدرة العالية لفهم المنهجية والأساليب والتقنيات المستخدمة لتنفيذ وإدارة نظام إدارة أمن المعلومات بطريقة فعالة.",
      "فهم كيفية تفسير متطلبات المواصفة الدولية ISO 27001 في السياق المحددة للمؤسسة.",
      "التعرف على الكيفية الصحيحة لدعم المؤسسات لتخطيط، وتنفيذ، وإدارة، ومراقبة وتحسين نظام إدارة أمن المعلومات.",
      "اكتساب الخبرة لتقديم الاستشارات الفنية للمؤسسات في تنفيذ أفضل الممارسات لنظام إدارة أمن المعلومات.",
    ],
    days: [
      [
        "اليوم الأول",
        "مقدمة إلى المواصفة الدولية 27001 وبدء نظام إدارة أمن المعلومات",
      ],
      ["اليوم الثاني", "التخطيط لتنفيذ نظام إدارة أمن المعلومات"],
      ["اليوم الثالث", "تنفيذ نظام إدارة أمن المعلومات"],
      [
        "اليوم الرابع",
        "المراقبة والقياس والتحسين المستمر لنظام إدارة أمن المعلومات والتحضير لعملية التدقيق",
      ],
      ["اليوم الخامس", "الامتحان لنيل الشهادة"],
    ],
    targets: [
      "المديرون أو الاستشاريون المشاركون في إدارة أمن المعلومات",
      "المستشارون الخبراء الذين يسعون إلى اتقان تنفيذ نظام إدارة أمن المعلومات",
      "الأفراد المسؤولون عن الحفاظ على التوافق مع متطلبات النظام",
      "أعضاء فريق تنفيذ نظام إدارة أمن المعلومات",
    ],
    certificate:
      "بمجرد اجتياز الامتحان بنجاح وتحقيق شروط نيل الشهادة سيتسلم المتدرب شهادة الحضور بالاضافة لشهادة المنفذ المؤقت إلى أن يحقق شروط نيل الاعتراف وشهادة كبير مسؤولي التنفيذ بناء خبرته العملية في تنفيذ المشريع ولمدة سنتين من الخبرة العملية في إدارة أمن المعلومات",
    link: "دورة-كبير-مسؤولي-تنفيذ-نظام-ادارة-امن-معلومات-معتمدة",
  },

  {
    image: {},
    imageRelativePath: "course-2.jpg",
    alt: "hand writing",
    title:
      "دورة أساسيات تنفيذ نظام إدارة أمن المعلومات ISO27001 LI معتمدة من شركة PECB",
    contains: [
      "مدة الدورة 2 يومان",
      "بواقع 6 ساعات يوميا",
      "الحقيبة التدريبية تشمل المنهج معتمد + شهادة حضور + شهادة اعتماد بعد اجتياز الامتحان",
      "الدخول للامتحان وإعادة الامتحان مجانا",
      "يمكن التنسيق لتقديم الدورة بدولة تركيا مع تضمين أسعار القاعات وتكاليف السفر والإقامة بعرض منفصل",
    ],
    summary:
      "ستتيح لك هذه الدورة التدريبية تعلم العناصر الأساسية لتنفيذ وإدارة نظام إدارة زمن المعلومات كما هو محدد بالمواصفة الدولية 27001 . خلال هذه الدورة التدريبية ستتمكن من فهم الوحدات المكونة لنظام ISMS بما في ذلك سياسات النظام والإجراءات، قياس الااداء، التزام الإدارة، التدقيق الداخلي ومراجعة الإدارة والتحسين المستمر . بعد الانتهاء من الدورة يمكنكم التقدم للامتحان وطلب الحصول على إعتماد PECB بخصوص استيعابك للمنهجية والمتطلبات وإطار العمل والإدارة.",
    goals: [""],
    days: [
      [
        "اليوم الأول",
        "مقدمة في مفاهيم نظم إدارة أمن المعلومات ISMS حسب متطلبات ISO 27001",
      ],
      ["اليوم الثاني", "متطلبات نظام إدارة أمن المعلومات وامتحان الشهادة"],
    ],
    targets: [
      "الأفراد المشاركون في إدارة أمن المعلومات ISMS",
      "الأفراد الذين يرغبون في إكتساب المعرفة حول العمليات الرئيسية لنظم إدارة أمن المعلومات ISMS",
      "الأفراد المهتمون بتطوير مهاراتهم في تخصص إدارة أمن المعلومات",
    ],
    certificate:
      "بعد إكمال الإمتحان بنجاح يمكنكم التقدم بطلب الشهادة بناء على قواعد وسياسات إصدار الشهادات من PECB",
    link: "دورة-اساسيات-تنفيذ-نظام-ادارة-امن-معلومات-معتمدة",
  },

  {
    image: {},
    imageRelativePath: "course-3.jpg",
    alt: "man pressing against screen",
    title: "دورة مقدمة في نظام إدارة أمن المعلومات",
    contains: [
      "مدة الدورة يوم واحد",
      "بواقع 6 ساعات",
      "الحقيبة التدريبية تشمل العرض التقديمي + شهادة حضور",
    ],
    summary:
      "تشرح هذه الدورة التدريبية بشكل مفصل نظام إدارة أمن المعلومات ISMS مع تفسيرات واضحة لمبادئ أمن المعلومات وتقاطعها مع نظم الإدارة والعمليات لإنجاز إطار متكامل يدعم القرارات الاستراتيجية والعملياتية بالمؤسسات على المدى الطويل واليومي كما ويقدم للمتدربين فهم دقيق لمتطلبات المعيار الدولي آيزو 27001:2013 وكيفية تأثير هذا المعيار على الأفراد والشركات والجهات المرتبطة بها، أهم عناصر عائلة المعيار وضوابط الحماية بالملحق A  مع بيان لمسار الالتزام بالشهادة",
    goals: [
      "سواء أكان هدف مؤسستكم الالتزام بالمعيار أو غير ذلك ستزودك هذه الدورة بالقدرات العلمية لفهم المجرى الصحيح للامور الخاصة بكيفية إدارة أمن المعلومات ضمن المؤسسة مهم كان حجمها ونوع نشاطها وكيفية دمج عمليات أمن المعلومات بإدارة المخاطر والقدرة على إصدار الاحكام المناسبة فيما يخص ميزانيات الحماية ، كما وستمكنك هذه الدورة من القدرة على مناقشة المواضيع المتعلقة بأمن المعلومات وفهم توصيات ومقترحات إدارات تقنية المعلومات بالخصوص.",
    ],
    days: [["يوم واحد", "محاضرتين"]],
    targets: [
      "مالكي الشركات الخاصة وأصحاب القرار بمجالس الإدارات",
      "مدراء الشركات العامة والخاصة ومدراء الإدارات المختلفة",
      "مدراء ومهندسي تقنية المعلومات",
      "مدراء ومهندسي أمن المعلومات",
      "جميع المهتمين بتنمية مهارتهم بمجال أمن المعلومات",
    ],
    certificate:
      "بمجرد اجتياز الامتحان بنجاح وتحقيق شروط نيل الشهادة سيتسلم المتدرب شهادة الحضور من شركة التميز",
    link: "دورة-مقدمة-في-نظام-ادارة-امن-معلومات",
  },
]

module.exports.courses = courses
