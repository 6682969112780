import React from "react"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

import { makeStyles, Grid, Typography } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "./Slider"

const Hero = ({ background, aboutText }) => {
  const useStyles = makeStyles(theme => ({
    backgroundImageContainer: {
      width: "100%",
      height: "477px",
      position: "relative",
    },
    backgroundImage: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "477px",
      width: "99.1vw",
      zIndex: -1,
    },
    backgroundImageDarken: {
      position: "absolute",
      left: 0,
      top: 0,
      backgroundColor: aboutText ? "rgba(0,0,0,.7)" : "rgba(0,0,0,.2)",
      height: "477px",
      width: "100%",
      zIndex: -1,
    },
    content: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      margin: "0 auto",
      color: "#fff",
      textAlign: "center",
    },
    aboutTitles: {
      fontSize: "4.4rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "3.4rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.6rem",
      },
    },
    aboutBodyText: {
      fontSize: "2.4rem",
      width: "80%",
      [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },

    // Slider
    sliderContainer: {
      width: "100%",
      height: "477px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: customTheme.palette.primary.dark,
      position: "relative",
      // border: "inset",
    },
    slide: {
      height: "100%",
      width: "100%",
    },
    slideIndicator: {
      display: "flex",
      justifyContent: "center",
      transition: "all 1s",
      marginTop: "1rem",
      zIndex: 1,
    },
    circle: {
      marginTop: "5px",
      width: "10px",
      height: "10px",
      backgroundColor: customTheme.palette.common.lightGray,
      outline: "none",
      cursor: "pointer",
      borderRadius: "100%",
      "&:not(:last-child)": {
        marginLeft: "4px",
      },
    },
    red: {
      backgroundColor: theme.palette.primary.main,
    },
  }))

  const classes = useStyles()

  return background ? (
    // Normal background image
    <Grid
      container
      direction="column"
      className={classes.backgroundImageContainer}
      justify="center"
      alignContent="center"
    >
      <Grid item>
        <GatsbyImage
          image={background}
          alt="people meeting"
          className={classes.backgroundImage}
        />
        <div className={classes.backgroundImageDarken} />
      </Grid>
      {aboutText && (
        <Grid
          container
          direction="column"
          className={classes.content}
          justify="center"
        >
          <Grid item align="center">
            <Typography className={classes.aboutTitles} paragraph>
              {aboutText.title}
            </Typography>
          </Grid>
          <Grid item align="center">
            <Typography className={classes.aboutBodyText}>
              {aboutText.body}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    // hero slider
    <Grid container direction="column" style={{ marginBottom: "8rem" }}>
      <Grid item>
        <div className={classes.sliderContainer}>
          <div style={{ width: "100%", position: "absolute" }}>
            <Slider className={classes.slide} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default Hero
