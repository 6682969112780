export const naviLinks = [
  {
    name: "الرئيسية",
    link: "/",
    isActiveIndex: 0,
  },
  {
    name: "الخدمات الأمنية",
    link: "/services",
    isActiveIndex: 1,
  },
  {
    name: "الدورات التدريبية",
    link: "/training_courses",
    isActiveIndex: 2,
  },
  {
    name: "المدونة",
    link: "/blog",
    isActiveIndex: 3,
  },
  {
    name: "تواصل معنا",
    link: "/contact",
    isActiveIndex: 4,
  },
  {
    name: "حولنا",
    link: "/about",
    isActiveIndex: 5,
  },
]
