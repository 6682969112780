import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { naviLinks } from "./constants/NaviLinks"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  makeStyles,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Hidden,
  IconButton,
  useScrollTrigger,
} from "@material-ui/core"
import {
  MenuOutlined,
  HomeOutlined,
  InfoOutlined,
  EmailOutlined,
  WorkOutlineOutlined,
  ClearOutlined,
  AssignmentOutlined,
  WorkOutlined,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  bar: {
    transition: "all .2s",
  },
  tabsContainer: {
    marginRight: "1rem",
  },
  tab: {
    // ...theme.typography.tab,
    fontSize: "inherit",
    minWidth: 10,
    marginLeft: "20px",
    "&:hover": {
      pointer: "cursor",
      color: "#fff",
      textDecoration: "none",
    },
  },
  logoContainer: {
    marginRight: "auto",
    marginTop: "-4px",
    // height: "100%",
    // width: "10rem",
  },
  logoButton: {
    // height: "4rem",
    // width: "10rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.0);",
    },
  },
  image: {
    // height: "10rem",
    width: "10rem",
    top: ".5rem",
  },
  headerMarginBottom: {
    marginBottom: "100px",
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
  drawerMenuIcon: {
    color: "#fff",
    position: "relative",
    "& > span > svg": {
      fontSize: "40px",
    },
  },
  drawerItemText: {
    "& > span": {
      fontSize: "1.6rem",
      color: "#fff",
      textAlign: "right",
    },
  },
  drawerItemIcon: {
    color: "#fff",
  },
  beforeShrink: {
    padding: "2rem 13rem",
    transition: "all .2s",
    fontSize: "1.6rem",
    transform: "scale(1.2)",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 8rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 5rem",
    },
  },
  afterShrink: {
    transition: "all .2s",
    fontSize: "1.6rem",
    padding: ".5rem 0rem",
  },
}))

const query = graphql`
  {
    allFile(
      filter: { relativePath: { in: ["arLogoTransparent.png", "arLogo.png"] } }
    ) {
      logos: nodes {
        childImageSharp {
          gatsbyImageData(height: 80)
        }
      }
    }
  }
`

const Header = () => {
  const {
    allFile: { logos },
  } = useStaticQuery(query)
  const [value, setValue] = useState(0)
  const classes = useStyles()

  // Shrinking the appbar
  function ShrinkOnScroll(props) {
    const { children } = props
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    })

    return React.cloneElement(children, {
      className: trigger ? classes.afterShrink : classes.beforeShrink,
    })
  }

  // Toggling drawer stuff
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpenDrawer(open)
  }

  // Highlighting current page navigation button
  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  // Remember which page to highlight its navigation button
  useEffect(() => {
    naviLinks.forEach(link => {
      if (link.link === window.location.pathname) {
        if (value !== link.isActiveIndex) {
          setValue(link.isActiveIndex)
        }
      }
    })
  }, [setValue, value])

  const tabs = (
    <Grid item className={classes.tabsContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="navigation links"
        indicatorColor="primary"
      >
        {naviLinks.map(link => (
          <Tab
            key={link.link}
            label={link.name}
            component={Link}
            to={link.link}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </Grid>
  )

  const drawerIcon = index => {
    switch (index) {
      case 0:
        return <HomeOutlined />

      case 1:
        return <WorkOutlineOutlined />
      case 2:
        return <WorkOutlined />
      case 3:
        return <AssignmentOutlined />
      case 4:
        return <EmailOutlined />
      case 5:
        return <InfoOutlined />
      default:
        break
    }
  }

  const drawer = (
    <SwipeableDrawer
      // disableBackdropTransition={!iOS}
      // disableDiscovery={iOS}
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      onOpen={() => setOpenDrawer(true)}
      anchor={"bottom"}
      classes={{ paper: classes.drawer }}
    >
      <List>
        {naviLinks.map(link => (
          <ListItem
            button
            onClick={() => {
              setOpenDrawer(false)
              setValue(link.isActiveIndex)
            }}
            component={Link}
            to={link.link}
            key={link}
          >
            <ListItemIcon className={classes.drawerItemIcon}>
              {drawerIcon(link.isActiveIndex)}
            </ListItemIcon>
            <ListItemText
              primary={link.name}
              className={classes.drawerItemText}
            />
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  )

  return (
    <header className={classes.bar}>
      <ShrinkOnScroll>
        <AppBar position="fixed" elevation={4} className={classes.bar}>
          <Toolbar variant="dense" disableGutters className={classes.bar}>
            <Grid container className="contentContainer">
              <Hidden smDown>{tabs}</Hidden>
              <Hidden mdUp>
                <IconButton
                  onClick={toggleDrawer(!openDrawer)}
                  className={classes.drawerMenuIcon}
                >
                  {openDrawer ? <ClearOutlined /> : <MenuOutlined />}
                </IconButton>
                {drawer}
              </Hidden>
              <Grid item className={classes.logoContainer}>
                <Button
                  onClick={() => setValue(0)}
                  disableRipple
                  component={Link}
                  to="/"
                  className={classes.logoButton}
                >
                  <GatsbyImage
                    image={getImage(logos[1].childImageSharp)}
                    alt="logo"
                    className={classes.image}
                  />
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ShrinkOnScroll>
      <div className={classes.headerMarginBottom} />
    </header>
  )
}

export default Header
