import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../gatsby-theme-material-ui-top-layout/customTheme"
import Helmet from "react-helmet"
import Header from "./Header"
import { Container } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Footer from "./Footer"
import Hero from "../components/Hero"

function Layout({ pageMeta, children, isHero, background, aboutText }) {
  return (
    <>
      <Helmet>
        <html lang="ar" />
        <title>التميز | {pageMeta && pageMeta.title}</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Abdurrahman Murad" />
        <meta
          name="description"
          content={pageMeta.description ? pageMeta.description : ""}
        />
        <meta
          name="keywords"
          content={pageMeta.keywords ? pageMeta.keywords.join(",") : ""}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        {isHero ? (
          <Hero />
        ) : background ? (
          aboutText ? (
            <Hero background={background} aboutText={aboutText} />
          ) : (
            <Hero background={background} />
          )
        ) : undefined}
        <Container className={"contentContainer"}>{children}</Container>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout
