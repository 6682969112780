import { createMuiTheme } from "@material-ui/core"

const darkRed = "#C30000"
const red = "#FF3300"
const darkGray = "#333333"
const lightGray = "#95989A"
const jfFlat = "JF Flat"

//

const customTheme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: red,
      dark: darkRed,
    },
    common: {
      darkGray: darkGray,
      lightGray: lightGray,
    },
  },
  typography: {
    fontFamily: ["JF Flat", "Times New Roman", "serif"].join(","),
    cardTitle: {
      fontSize: "4rem",
    },
    cardInfo: {
      fontSize: "2.4rem",
    },
    titleLG: {
      fontSize: "3.2rem",
    },
    titleMD: {
      fontSize: "2rem",
    },
    titleSM: {
      fontSize: "1.6rem",
    },
    titleXS: {
      fontSize: "1.2rem",
    },
    descMD: {
      fontSize: "1.6rem",
    },
    descSM: { fontSize: "1.2rem" },
    NormalTxt: { fontSize: "2rem" },
    tab: {
      fontSize: "1.6rem",
      fontWeight: 700,
    },
    button: {
      fontSize: "2rem",
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [jfFlat],
        },
      },
    },
  },
})

export default customTheme
