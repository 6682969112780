import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "react-bootstrap/Carousel"
import "bootstrap/dist/css/bootstrap.min.css"
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { SLIDER_DATA } from "./constants/SliderData"

const query = graphql`
  {
    allFile(filter: { relativePath: { in: ["hero-img.jpg"] } }) {
      nodes {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  card: {
    position: "relative",
    width: "100%",
    height: "477px",
    boxShadow: "2px 4px 4px rgba(0,0,0, .5)",
    display: "flex",
    alignItems: "center",
    borderRadius: "0",
  },
  shadowOverlay: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.4)",
    zIndex: 1,
  },
  backgroundImageFix: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "477px",
  },
  slideDescription: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    padding: "2rem 4.5rem",
    backdropFilter: "brightness(60%)",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      padding: ".5rem .5rem",
    },
  },
  cardTitle: {
    ...theme.typography.cardTitle,
    fontWeight: "bold",
    textAlign: "right",
    color: "#fff",
    marginBottom: "1.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  cardInfo: {
    ...theme.typography.cardInfo,
    fontSize: "2.4rem",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  button: {
    ...theme.typography.button,
    marginTop: "1.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    "&:hover": {
      color: "#fff",
    },
  },
}))

const Slider = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  const image = getImage(data.allFile.nodes[0].childrenImageSharp[0])

  return (
    <Carousel fade className="rtl">
      {SLIDER_DATA.map((slide, index) => {
        return (
          <Carousel.Item interval={3000} key={`${slide} ${index}`}>
            <GatsbyImage
              image={image}
              alt="laptop and paper"
              className={classes.backgroundImage}
              loading="eager"
            />
            <Carousel.Caption>
              <Grid
                container
                direction="column"
                justify="center"
                className={`contentContainer ${classes.slideDescription} `}
              >
                <Grid item>
                  <Typography className={classes.cardTitle}>
                    {slide.title}
                  </Typography>
                </Grid>
                {/* {Array.isArray(slide.info) ? (
                  slide.info.map(infoItem => {
                    return (
                      <Grid item key={infoItem}>
                        <Typography className={classes.cardInfo}>
                          {infoItem}
                        </Typography>
                      </Grid>
                    )
                  })
                ) : ( */}
                <Grid item key={slide.info}>
                  <Typography className={classes.cardInfo}>
                    {slide.info}
                  </Typography>
                </Grid>
                {/* )} */}
                {slide.link && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/training_courses/${slide.link}`}
                      className={classes.button}
                    >
                      لمعرفة المزيد
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

export default Slider
